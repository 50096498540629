:root {
    --ant-border-color: initial; /* Initial fallback value */
    --fluid-font-size: calc(12px + 1vw); /* Example fluid font size calculation */
}

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    /*overflow: hidden; !* Prevent scrolling *!*/
}

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
    20%, 40%, 60%, 80% { transform: translateX(5px); }
}

.shake-and-bake {
    animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
}
/*.tiles {*/
/*    flex: 1;*/
/*    padding: 20px;*/
/*}*/

.tile {
    background-color: transparent;
    width: 50px;
    height: 50px;
    perspective: 1000px;
    border-radius: 12px;
    border-width: 1px;
}

.key-button {
    flex: 1 1 auto;
    text-align: center;
    cursor: pointer;
    border-radius: 8px;
    margin: 2px 1px 2px 1px;
    border: 1px solid var(--ant-border-color, gray);
    padding: 6px 12px;
    font-size: var(--fluid-font-size);
    min-width: 9vw;
    max-width: calc(10% - 10px); /* Ensures a maximum of 10 buttons per row */
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: lightgray;

}

/* Styles for extra small devices */
@media (max-width: 400px) {
    .key-button {
        padding: 4px 8px;
        max-width: calc(10% - 8px); /* Adjusted for smaller devices */

    }

    .tile {
        width: 45px;
        height: 45px;
    }
}

/* Styles for small devices */
@media (min-width: 401px) and (max-width: 600px) {
    .key-button {
        padding: 4px 8px;
        max-width: calc(10% - 8px); /* Adjusted for smaller devices */

    }

    .tile {
        width: 50px;
        height: 50px;
    }
}

/* Styles for medium devices */
@media (min-width: 601px) and (max-width: 899px) {
    .key-button {
        padding: 6px 12px;
        margin:3px
    }

    .tile {
        width: 60px;
        height: 60px;
        margin-bottom: 3px;
    }
}

/* Styles for large devices */
@media (min-width: 900px) {
    .key-button {
        padding: 6px 18px;
        margin: 4px;
        min-width: 70px;
        max-width: 70px;
        min-height: 70px;
        max-height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .tile {
        width: 80px;
        height: 80px;
        margin-bottom: 5px;
    }
}
